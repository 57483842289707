var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "Reports__Import__View" }, [
    _c("h1", [_vm._v("ייבוא חוזי נהגים")]),
    _c("div", { staticClass: "Reports__Import__View__Steps" }, [
      _c(
        "section",
        [
          _c(
            "b-steps",
            {
              scopedSlots: _vm._u([
                {
                  key: "navigation",
                  fn: function(ref) {
                    var previous = ref.previous
                    var next = ref.next
                    return [
                      _c(
                        "div",
                        {
                          directives: [
                            {
                              name: "show",
                              rawName: "v-show",
                              value: _vm.activeStep !== 5,
                              expression: "activeStep !== 5"
                            }
                          ],
                          staticClass:
                            "TollRoads__Import__View__Steps__Navigations"
                        },
                        [
                          _c(
                            "b-button",
                            {
                              attrs: {
                                outlined: "",
                                type: "is-primary",
                                icon: ""
                              },
                              on: {
                                click: function($event) {
                                  $event.preventDefault()
                                  return _vm.onPrevious(previous)
                                }
                              }
                            },
                            [_vm._v(" חזור ")]
                          ),
                          _c(
                            "b-button",
                            {
                              attrs: {
                                outlined: "",
                                type: "is-primary",
                                icon: ""
                              },
                              on: {
                                click: function($event) {
                                  $event.preventDefault()
                                  return _vm.onNext(next)
                                }
                              }
                            },
                            [_vm._v(" המשך ")]
                          )
                        ],
                        1
                      )
                    ]
                  }
                }
              ]),
              model: {
                value: _vm.activeStep,
                callback: function($$v) {
                  _vm.activeStep = $$v
                },
                expression: "activeStep"
              }
            },
            [
              _c("b-step-item", { attrs: { label: "בחירת קובץ" } }, [
                _c(
                  "div",
                  { staticClass: "ChooseFile__Step" },
                  [
                    _c(
                      "b-field",
                      { attrs: { label: "בחר קובץ לייבוא" } },
                      [
                        _c(
                          "b-field",
                          {
                            staticClass: "file is-primary",
                            class: { "has-name": !!_vm.file }
                          },
                          [
                            _c(
                              "b-upload",
                              {
                                staticClass: "file-label",
                                attrs: { rounded: "" },
                                model: {
                                  value: _vm.file,
                                  callback: function($$v) {
                                    _vm.file = $$v
                                  },
                                  expression: "file"
                                }
                              },
                              [
                                _c(
                                  "span",
                                  { staticClass: "file-cta" },
                                  [
                                    _c("b-icon", {
                                      staticClass: "file-icon",
                                      attrs: { icon: "upload" }
                                    }),
                                    _c("span", { staticClass: "file-label" }, [
                                      _vm._v("בחר קובץ...")
                                    ])
                                  ],
                                  1
                                ),
                                _vm.file
                                  ? _c("span", { staticClass: "file-name" }, [
                                      _vm._v(" " + _vm._s(_vm.file.name) + " ")
                                    ])
                                  : _vm._e()
                              ]
                            ),
                            _c(
                              "a",
                              {
                                staticStyle: { "margin-right": "10px" },
                                attrs: {
                                  href:
                                    "https://api.betterway.co.il/ImportSamples/תבנית יבוא חוזה נהגים.xlsx",
                                  target: "_blank"
                                }
                              },
                              [_c("b-button", [_vm._v(" קובץ דוגמא ")])],
                              1
                            )
                          ],
                          1
                        )
                      ],
                      1
                    )
                  ],
                  1
                )
              ]),
              _c(
                "b-step-item",
                { attrs: { clickable: false, label: "הבאת מידע" } },
                [
                  _c(
                    "div",
                    { staticClass: "Progress__Step" },
                    [
                      _c("b-progress", {
                        attrs: {
                          type: "is-primary",
                          value: _vm.progress,
                          "show-value": "",
                          format: "percent"
                        }
                      })
                    ],
                    1
                  )
                ]
              ),
              _c("b-step-item", { attrs: { label: "מיפוי שדות" } }, [
                _c(
                  "div",
                  { staticClass: "starting-row" },
                  [
                    _c(
                      "b-field",
                      { attrs: { label: "ייבא נתונים החל משורה" } },
                      [
                        _c("b-numberinput", {
                          attrs: { min: "0" },
                          model: {
                            value: _vm.startingRow,
                            callback: function($$v) {
                              _vm.startingRow = $$v
                            },
                            expression: "startingRow"
                          }
                        })
                      ],
                      1
                    )
                  ],
                  1
                ),
                _c(
                  "div",
                  { staticClass: "table-scrollable" },
                  [
                    _vm.headers.length
                      ? _c("table-selectable", {
                          ref: "table",
                          attrs: {
                            "show-row-number": true,
                            "starting-row": _vm.startingRow,
                            headers: _vm.headers,
                            data: _vm.table
                          }
                        })
                      : _vm._e()
                  ],
                  1
                )
              ]),
              _c(
                "b-step-item",
                { attrs: { label: "מייבא נתונים", visible: _vm.isImporting } },
                [
                  _c(
                    "div",
                    { staticClass: "importing-step" },
                    [
                      _c("b-loading", {
                        attrs: { "is-full-page": false, "can-cancel": false },
                        model: {
                          value: _vm.isImporting,
                          callback: function($$v) {
                            _vm.isImporting = $$v
                          },
                          expression: "isImporting"
                        }
                      })
                    ],
                    1
                  )
                ]
              ),
              _c(
                "b-step-item",
                { staticClass: "table-item", attrs: { label: "סטטוס" } },
                [
                  _c("div", { staticClass: "status-step" }, [
                    _c(
                      "div",
                      {
                        staticClass: "results-reports",
                        staticStyle: { "margin-top": "20px" }
                      },
                      [
                        _c(
                          "div",
                          {
                            staticStyle: {
                              position: "relative",
                              display: "flex",
                              "align-items": "center",
                              gap: "10px"
                            }
                          },
                          [
                            _vm.isLoading
                              ? _c("div", [
                                  _vm._v(
                                    "דוח: " +
                                      _vm._s(
                                        _vm.totalReports +
                                          " / " +
                                          _vm.currentReport
                                      )
                                  )
                                ])
                              : _vm._e(),
                            _vm.isLoading
                              ? _c("b-icon", {
                                  attrs: {
                                    pack: "fas",
                                    icon: "sync-alt",
                                    "custom-class": "fa-spin"
                                  }
                                })
                              : _vm._e()
                          ],
                          1
                        ),
                        this.succeeded
                          ? _c("p", [
                              _vm._v("נהגים שנקלטו: " + _vm._s(_vm.succeeded))
                            ])
                          : _vm._e(),
                        this.notSucceeded
                          ? _c("p", [
                              _vm._v(
                                "נהגים שלא נקלטו: " + _vm._s(_vm.notSucceeded)
                              )
                            ])
                          : _vm._e()
                      ]
                    ),
                    _vm.hideResults
                      ? _c("table", { staticClass: "table" }, [
                          _c("thead", [
                            _c(
                              "tr",
                              _vm._l(_vm.results.headers, function(
                                header,
                                index
                              ) {
                                return _c(
                                  "th",
                                  { key: index, staticClass: "result" },
                                  [_vm._v(" " + _vm._s(header) + " ")]
                                )
                              }),
                              0
                            )
                          ]),
                          _c(
                            "tbody",
                            _vm._l(_vm.results.rows, function(row, rowIndex) {
                              return _c(
                                "tr",
                                {
                                  key: rowIndex,
                                  class: { "has-error": !row.Succeeded }
                                },
                                _vm._l(row.data, function(value, valueIndex) {
                                  return _c("td", { key: valueIndex }, [
                                    _vm._v(" " + _vm._s(value) + " ")
                                  ])
                                }),
                                0
                              )
                            }),
                            0
                          )
                        ])
                      : _vm._e()
                  ])
                ]
              ),
              _vm.activeStep === 5 && !_vm.isLoading
                ? [
                    _c(
                      "div",
                      { staticClass: "mt-5" },
                      [
                        _c(
                          "b-button",
                          {
                            attrs: { type: "is-primary" },
                            on: { click: _vm.reset }
                          },
                          [_vm._v("התחל ייבוא חדש")]
                        ),
                        _c(
                          "b-button",
                          {
                            attrs: {
                              loading: _vm.isExcelLoading,
                              type: "is-light"
                            },
                            on: { click: _vm.exportToExcel }
                          },
                          [_c("span", [_vm._v("ייצוא נתונים לאקסל ")])]
                        )
                      ],
                      1
                    )
                  ]
                : _vm._e()
            ],
            2
          )
        ],
        1
      )
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }